import React from "react";
import EhlLogo from "../assets/ehl.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-black md:px-12 md:px-0">
      <div className="container">
        <div className="flex flex-col py-14 pb md:flex-row justify-between">
          <a
            className="text-white text-15 hover:text-green-primary"
            href="http://www.nmlsconsumeraccess.org/"
            target="_blank"
          >
            NMLS Consumer Access
          </a>
          <a
            className="text-white text-15 hover:text-green-primary"
            href="https://onetrusthomeloans.com/licensing-information/"
            target="_blank"
          >
            Locations and Licensing
          </a>
          <Link className="text-white text-15 hover:text-green-primary" to="/privacy-policy">
            Privacy
          </Link>
          <Link className="text-white text-15 hover:text-green-primary" to="/terms-of-use/">
            SMS Terms of Use
          </Link>
          <Link className="text-white text-15 hover:text-green-primary" to="/contact-us/">
            Contact A Home Loan Expert
          </Link>
          <Link className="text-white text-15 hover:text-green-primary" to="/cookie-information-opt-out">
            Cookie Information & Opt-Out
          </Link>
          <img className="w-20" src={EhlLogo} alt="Equal Housing icon" />
        </div>
      </div>
      <div className="w-full ">
        <p className="text-15 text-white text-center pb-12">
          © 2013 - {new Date().getFullYear()}. CalCon Mutual Mortgage LLC NMLS# 46375 3838 Camino del Rio N Suite 305,
          San Diego, CA 92108. Corporate Phone (888) 488-3807.
        </p>
        {/* <p className="text-15 text-white w-160 compliance-container">
            OneTrust Home Loans is a trade name of the legal entity, CalCon
            Mutual Mortgage LLC, a Delaware LLC (NMLS #46375). The OneTrust name
            has been registered in most states where CalCon operates, and in
            those states where it is not registered, we operate as CalCon. Equal
            Housing Lender.
          </p> */}
      </div>
    </footer>
  );
}

export default Footer;
